.pulse {
  animation: pulse-animation 2s infinite;
  background: white;
}
.pulse:hover {
  background: white !important;
}
.pulse:focus {
  background: white !important;
}
.pulse:active {
  background: white !important;
}
.pulse:visited {
  background: white !important;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(249 92 73);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}