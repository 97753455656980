/* styles.css */
.table-container {
    width: 100%;
    overflow-x: auto;
    height: auto;
    background-color: white;
    z-index: 999;
    margin-bottom: 4rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  @media screen and (max-width: 600px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }
  
    tr {
      margin-bottom: 15px;
    }
  
    td {
      text-align: right;
      position: relative;
      padding-left: 50%;
    }
  
    td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  